import { useState, useEffect } from "react";
import * as Auth from "../Api/Auth";

export const useAuthByPhone = () => {
  const [isPhoneEnabled, setIsPhoneEnabled] = useState(false);

  useEffect(() => {
    Auth.getAuthByPhoneIsEnabled().then((res) =>
      setIsPhoneEnabled(res.text !== "false")
    );
    console.log("isPhoneEnabled ", isPhoneEnabled);
  }, [isPhoneEnabled]);

  return { isPhoneEnabled };
};
