import React, { useRef } from "react";

const EventStatistics = ({ statistics }) => {
  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);

  return statistics ? (
    <div
      className={
        "details-votes-page-datetime-end-reg-start-vote__wrapper statistics"
      }
    >
      <div
        className={
          "details-votes-page-datetime-end-reg-start-vote__datetime-info-end-reg"
        }
      >
        <div className="datetime-info__start-vote statistics__header">
          <h2>Ход регистрации</h2>
          <div className="statistics__info">{`${statistics?.numRegisteredUsers} / ${statistics?.numUsers} зарегистрированных`}</div>
        </div>
        <div className="start-vote__time-info-input-vote">
          <input
            readOnly
            ref={firstInputRef}
            min={0}
            max={100}
            value={statistics?.registeredUsersPercentage || 0}
            className="datetime-info__range datetime-info__range-orange"
            type="range"
          />
          <div
            id="width-time-info-input-vote"
            className="time-info-input-reg__timer-strip"
            style={
              statistics
                ? { width: `${statistics?.registeredUsersPercentage}%` }
                : { width: `${0}%` }
            }
          />
        </div>
      </div>

      <div
        className={
          "details-votes-page-datetime-end-reg-start-vote__datetime-info-end-vote active"
        }
      >
        <div className="datetime-info__start-vote statistics__header">
          <h2>Ход голосования</h2>
          <div className="statistics__info">{`${statistics?.numVotedUsers} / ${statistics?.numUsers} проголосовавших`}</div>
        </div>
        <div className="start-vote__time-info-input-vote">
          <input
            readOnly
            ref={secondInputRef}
            min={0}
            max={100}
            value={statistics?.votedUsersPercentage || 0}
            className="datetime-info__range datetime-info__range-green"
            type="range"
          />
          <div
            id="width-time-info-input-end-vote"
            className="time-info-input-vote__timer-strip"
            style={
              statistics
                ? { width: `${statistics?.votedUsersPercentage}%` }
                : { width: `${0}%` }
            }
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default EventStatistics;
