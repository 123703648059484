import React from "react";
import './NameStatusRegAndVote.css';

const NameStatusRegAndVote = ({nameStatusRegAndVote}) => {

    return (
        <>
            <span>{nameStatusRegAndVote}</span>
        </>
    )
}

export default NameStatusRegAndVote;