import React from "react";
import "./DetailsVotesPageResultGridTable.css";

const DetailsVotesPageResultGridTable2 = (props) => {
  const { answers, question } = props;

  const sortedAnswers = answers.sort((a, b) => +a.title - +b.title);

  console.log("answers: ", answers);
  console.log("question: ", question);

  const answersArray = answers[0].columns.map((it, i) => ({
    id: it.id,
    value: i + 1,
  }));

  return (
    <>
      <h3
        className="details-votes-page-result-question-grid__title"
        style={{ marginBottom: "15px" }}
      >
        Ответы участников
      </h3>
      <div
        className="details-votes-page-result-question-grid__tables-container table-answers"
        style={{ marginBottom: 50, width: "100%" }}
      >
        <div
          className="details-votes-page-result-grid-table__wrapper"
          style={{ borderBottom: "none", maxWidth: "100%" }}
        >
          <table className="details-votes-page-result-grid-table__table table2">
            <tbody>
              <tr
                style={{
                  borderBottom: "1px solid rgba(54, 59, 77, 0.3)",
                }}
              >
                <th style={{ whiteSpace: "nowrap", padding: "20px" }}>
                  Участники / Доклады
                </th>
                <th style={{ whiteSpace: "nowrap", padding: "20px" }}>
                  Проект
                </th>
                <th
                  style={{
                    whiteSpace: "nowrap",
                    padding: "20px",
                    width: "110px",
                  }}
                >
                  Баллы
                </th>
                {/* {sortedAnswers.map((answer) => (
                  <th key={answer.id} style={{ padding: 10 }}>№{answer.title}</th>
                ))} */}
              </tr>
              {question.users.map((user) => {
                return user.answers.map((answer, i) => (
                  <>
                    <tr
                      key={user.id}
                      style={{
                        borderBottom:
                          i === question.users.length - 1
                            ? "none"
                            : "1px solid rgba(54, 59, 77, 0.3)",
                      }}
                    >
                      <td style={{ padding: "10px 20px", width: "35%" }}>
                        {i === 0 && (user.name || "Имя не указано")}
                      </td>

                      <td style={{ padding: "20px " }}>
                        {answers.find((it) => it.id === answer.id).title || "-"}
                      </td>

                      <td style={{ padding: "20px " }} key={answer.id}>
                        {answersArray.find((it) => it.id === answer.values[0])
                          .value || "-"}
                      </td>

                      {/* {user.answers.map((answer) => (
                        <td style={{ padding: "20px 10px" }} key={answer.id}>
                          {answersArray.find((it) => it.id === answer.values[0])
                            .value || "-"}
                        </td>
                      ))} */}
                    </tr>
                  </>
                ));
              })}

              {answers.map((answer, i) => (
                <tr
                  key={answer.id}
                  style={{
                    fontWeight: "bold",
                    borderBottom:
                      i === answers.length - 1
                        ? "none"
                        : "1px solid rgba(54, 59, 77, 0.3)",
                  }}
                >
                  <td style={{ padding: "20px " }}>{i === 0 && `Итого:`}</td>
                  <td style={{ padding: "20px " }}>{answer.title}</td>
                  <td style={{ padding: "20px " }}>
                    {answer.columns.reduce(
                      (acc, o) =>
                        +o.favor >= 0 && +o.value >= 0
                          ? acc + +o.favor * +o.value
                          : acc,
                      0
                    )}
                  </td>
                </tr>
              ))}

              {/* <tr style={{ fontWeight: "bold" }}>
                <td style={{ padding: "20px" }}>Итого:</td>
                {sortedAnswers.map((answer) => (
                  <td key={answer.id} style={{ padding: "20px 10px" }}>
                    {answer.title}
                  </td>
                ))}

                {sortedAnswers.map((answer) => (
                  <td key={answer.id} style={{ padding: "20px 10px" }}>
                    {answer.columns.reduce(
                      (acc, o) =>
                        +o.favor >= 0 && +o.value >= 0
                          ? acc + +o.favor * +o.value
                          : acc,
                      0
                    )}
                  </td>
                ))}
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>

      {/* <h3
        className="details-votes-page-result-question-grid__title"
        style={{ marginBottom: "15px" }}
      >
        Ответы участников
      </h3>
      <div
        className="details-votes-page-result-question-grid__tables-container table-answers"
        style={{ marginBottom: 50 }}
      >
        <div
          className="details-votes-page-result-grid-table__wrapper"
          style={{ borderBottom: "none", maxWidth: "100%" }}
        >
          <table className="details-votes-page-result-grid-table__table table2">
            <tbody>
              <tr
                style={{
                  borderBottom: "1px solid rgba(54, 59, 77, 0.3)",
                }}
              >
                <th style={{ whiteSpace: "nowrap" }}>Участники / Доклады</th>
                {sortedAnswers.map((answer) => (
                  <th key={answer.id} style={{ padding: 10 }}>
                    №{answer.title}
                  </th>
                ))}
              </tr>
              {question.users.map((user, i) => (
                <tr
                  key={user.id}
                  style={{
                    borderBottom:
                      i === question.users.length - 1
                        ? "none"
                        : "1px solid rgba(54, 59, 77, 0.3)",
                  }}
                >
                  <td style={{ padding: "10px 20px" }}>
                    {user.name || "Имя не указано"}
                  </td>

                  {user.answers.map((answer) => (
                    <td style={{ padding: "20px 10px" }} key={answer.id}>
                      {answersArray.find((it) => it.id === answer.values[0])
                        .value || "-"}
                    </td>
                  ))}
                </tr>
              ))}

              <tr style={{ fontWeight: "bold" }}>
                <td style={{ padding: "20px" }}>Итого:</td>

                {sortedAnswers.map((answer) => (
                  <td key={answer.id} style={{ padding: "20px 10px" }}>
                    {answer.columns.reduce(
                      (acc, o) =>
                        +o.favor >= 0 && +o.value >= 0
                          ? acc + +o.favor * +o.value
                          : acc,
                      0
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      <h3
        className="details-votes-page-result-question-grid__title"
        style={{ marginBottom: "15px" }}
      >
        Сумма баллов
      </h3>
      <div
        className="details-votes-page-result-question-grid__tables-container"
        style={{ width: "100%" }}
      >
        <div
          className="details-votes-page-result-grid-table__wrapper"
          style={{ borderBottom: "none", maxWidth: "100%" }}
        >
          <table
            className="details-votes-page-result-grid-table__table table2"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr
                style={{
                  borderBottom: "1px solid rgba(54, 59, 77, 0.3)",
                }}
              >
                <th style={{width: "110px"}}>№</th>
                <th>Доклад</th>
                <th
                  style={{
                    whiteSpace: "nowrap",
                    padding: "20px",
                    width: "110px",
                  }}
                >
                  Баллы
                </th>
              </tr>
              {answers
                .sort(
                  (a, b) =>
                    b.columns.reduce(
                      (acc, o) =>
                        +o.favor >= 0 && +o.value >= 0
                          ? acc + +o.favor * +o.value
                          : acc,
                      0
                    ) -
                    a.columns.reduce(
                      (acc, o) =>
                        +o.favor >= 0 && +o.value >= 0
                          ? acc + +o.favor * +o.value
                          : acc,
                      0
                    )
                )
                .map((answer, i) => (
                  <tr
                    key={answer.id}
                    style={{
                      borderBottom:
                        i === answers.length - 1
                          ? "none"
                          : "1px solid rgba(54, 59, 77, 0.3)",
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>{answer.title}</td>
                    <td>
                      {answer.columns.reduce(
                        (acc, o) =>
                          +o.favor >= 0 && +o.value >= 0
                            ? acc + +o.favor * +o.value
                            : acc,
                        0
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DetailsVotesPageResultGridTable2;
