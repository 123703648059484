import React from "react";
import { Link } from "react-router-dom";
import "./VotesPageSuccessRegLaterModal.css";

const VotesPageSuccessRegLaterModal = (props) => {
  const {
    isActive,
    handleShowSuccessModal,
    successModalText,
    newEventId = "",
  } = props;

  setTimeout(() => {
    if (isActive) {
      handleShowSuccessModal();
    }
  }, 10000);

  return (
    <div
      className={
        isActive
          ? "details-votes-success-reg-modal__wrapper active"
          : "details-votes-success-reg-modal__wrapper"
      }
    >
      <div
        className={
          isActive
            ? "details-votes-success-reg-modal__content active"
            : "details-votes-success-reg-modal__content"
        }
      >
        <span>{successModalText}</span>
        {newEventId ? (
          <Link
            className={"details-votes-success-reg-modal__button"}
            to={`/details-vote/${newEventId}`}
            onClick={() => {
              handleShowSuccessModal();
              localStorage.removeItem('currentEvent');
              localStorage.setItem('currentEvent', JSON.stringify({
                id: newEventId,
              }));
            }}
          >
            Перейти
          </Link>
        ) : (
          <button
            className={"details-votes-success-reg-modal__button"}
            onClick={handleShowSuccessModal}
          >
            Понятно!
          </button>
        )}
      </div>
    </div>
  );
};

export default VotesPageSuccessRegLaterModal;
