import React, {useEffect, useState} from "react";
import './CardQuestionCellsTable.css';

const CardQuestionCellsTable = (props) => {

    const {
        varinantAnswer,
        result
    } = props;

    const [isAnswerString, setAnswerString] = useState(true);

    useEffect(() => {
        if (varinantAnswer.includes('https://') || varinantAnswer.includes('http://')) {
            setAnswerString(false)
        } else {
            setAnswerString(true)
        }
    }, [varinantAnswer])

    return (
        <>
            <tr>
                {isAnswerString ? <td>{varinantAnswer}</td> : <td><img alt={'картинка'} className="card-question-cell-table__image" src={varinantAnswer}/></td>}
                <td>{result}</td>
            </tr>
        </>
    )
}
export default CardQuestionCellsTable;
