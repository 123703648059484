import React from "react";
import '../NoMatchRoutes/NoMatchRoutes.css';
import {useLocation, useNavigate} from "react-router-dom";


export const NoMatchRoutes = (props) => {

    const {
        isLoggedIn
    } = props;

    const navigate = useNavigate();

    const returnMainPage = () => {
        if (!isLoggedIn) {
            navigate('/auth')
        } else {
            navigate('/')
        }
    }

    return (
        <div className="no-match-routes__container">
          <div className="no-match-routes">
              <h1 className="no-match-routes__title">Страница не найдена</h1>
              <p className="no-match-routes__information">Кажется что-то пошло не так! Страница, которую вы запрашиваете, не существует.</p>
              <button className="no-match-routes__button" onClick={returnMainPage}>Назад на главную</button>
          </div>
        </div>
    )
}