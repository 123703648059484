import React, { useState, useRef, useEffect } from "react";

import logo from "../../img/Auth_logo_crypto_veche.svg";
import rfbrLogo from "../../img/rfbrLogo.svg";

import show_pass_icon from "../../img/Auth_show_pass_icon.svg";
import hidden_pass_icon from "../../img/Auth_hidden_pass.svg";
import "./Authorization.scss";

import { useNavigate } from "react-router-dom";
import { Validation } from "../../utils/Validation";
// import { config } from "../../config";
import * as Auth from "../../Api/Auth";
import { useAuthByPhone } from "../../hooks/useAuthByPhone";

const Authorization = (props) => {
  const {
    handleLogin,
    handleLoginPhone,
    authError,
    handleRememberMe,
    isRememberMe,
    preLoaderBtn,
    config,
    setAuthError,
  } = props;

  const email = Validation();
  const password = Validation();
  const phoneCode = Validation();
  const [changeTypePass, setChangeTypePass] = useState("password");
  const [activeTypeDisplay, setActiveTypeDisplay] = useState(true);
  const linkButtonRegPage = useNavigate();
  const linkButtonForgot = useNavigate();
  const refBorderRedLogin = useRef();
  const refBorderRedPhone = useRef();
  const refBorderRedPass = useRef();
  const refPhoneCode = useRef(phoneCode.value);
  const [changeAuthPhone, setChangeAuthPhone] = useState(false);
  const [changeAuthEmail, setChangeAuthEmail] = useState(true);
  const [activeBtnDisactiveSMS, setActiveBtnDisactiveSMS] = useState(false);
  const [changeAuthPhoneEnterPass, setChangeAuthPhoneEnterPass] =
    useState(true);
  const [changeAuthPhoneEnterSMS, setChangeAuthPhoneEnterSMS] = useState(false);
  const [activeSMSField, setActiveSMSField] = useState(false);
  //const [userPhoneCode, setUserPhoneCode] = useState("");
  const [disactiveInputPhone, setDisactiveInputPhone] = useState(false);
  const [activeSMSFieldConfirmBtn, setActiveSMSFieldConfirmBtn] =
    useState(false);
  const [activeSMSFieldMessage, setActiveSMSFieldMessage] = useState(false);
  const [authPhoneCodeError, setAuthPhoneCodeError] = useState("");
  const [getPhoneCode, setGetPhoneCode] = useState(false);
  const [second, setSecond] = useState(60);
  let timeoutTimerSMSRef = useRef(null);
  let intervalTimerSMSRef = useRef(null);
  const changeTypeDisplay = config.type;


  function getChangeSelectPhone() {
    if (changeAuthEmail === true) {
      setChangeAuthEmail(false);
      setChangeAuthPhone(true);
      setDisactiveInputPhone(false);
      email.setValue("");
      password.setValue("");
      setActiveBtnDisactiveSMS(false);
      setActiveSMSFieldConfirmBtn(false);
      setAuthError("");
      refBorderRedPass.current.style.border = "1px solid rgba(54, 59, 77, 0.9)";
      setGetPhoneCode(false);
    }
  }

  function getChangeSelectEmail() {
    if (changeAuthPhone === true) {
      email.setValue("");
      password.setValue("");
      setChangeAuthEmail(true);
      setChangeAuthPhoneEnterSMS(false);
      setChangeAuthPhoneEnterPass(true);
      setChangeAuthPhone(false);
      setAuthError("");
      setActiveSMSFieldConfirmBtn(false);
      setActiveSMSField(false);
      setDisactiveInputPhone(false);
      setActiveBtnDisactiveSMS(false);
      setAuthPhoneCodeError("");
      refBorderRedPass.current.style.border = "1px solid rgba(54, 59, 77, 0.9)";
      setGetPhoneCode(false);
    }
  }

  function getChangeSelectPhonePass() {
    if (changeAuthPhoneEnterPass === true) {
      setChangeAuthPhoneEnterPass(false);
      setChangeAuthPhoneEnterSMS(true);
      setDisactiveInputPhone(false);
      setActiveBtnDisactiveSMS(false);
      setAuthError("");
      setActiveSMSFieldConfirmBtn(false);
      refBorderRedPass.current.style.border = "1px solid rgba(54, 59, 77, 0.9)";
      refBorderRedPhone.current.style.border =
        "1px solid rgba(54, 59, 77, 0.9)";
      setGetPhoneCode(false);
    }
  }

  function getChangeSelectSMS() {
    if (changeAuthPhoneEnterSMS === true) {
      setChangeAuthPhoneEnterSMS(false);
      setChangeAuthPhoneEnterPass(true);
      setActiveSMSField(false);
      setAuthError("");
      setAuthPhoneCodeError("");
      setDisactiveInputPhone(false);
      setActiveBtnDisactiveSMS(false);
      setActiveSMSFieldConfirmBtn(false);
      refBorderRedPhone.current.style.border =
        "1px solid rgba(54, 59, 77, 0.9)";
      setGetPhoneCode(false);
    }
  }

  useEffect(() => {
    if (changeTypeDisplay === "main") {
      setActiveTypeDisplay(true);
    } else {
      setActiveTypeDisplay(false);
    }
  }, [changeTypeDisplay]);

  const showHiddenPass = () => {
    if (changeTypePass === "password") {
      setChangeTypePass("text");
    } else {
      setChangeTypePass("password");
    }
  };

  function onGetPhoneCode(e) {
    e.preventDefault();
    const phoneNumberRegExp = /^(7)[0-9]{10}/;
    if (email.value !== "" && phoneNumberRegExp.test(email.value) !== false) {
      Auth.getPhoneCodeAuth(email.value)
        .then((res) => {
          if (res.text === `User with phone number ${email.value} not exist`) {
            setAuthError("Данного номера не существует");
            setAuthPhoneCodeError("");
          } else if (
            res.status === "failure" &&
            res.text === "Too early refreshing"
          ) {
            setAuthError("Превышен лимит запросов, попробуйте еще раз");
            setAuthPhoneCodeError("");
          } else {
            refBorderRedPhone.current.style.border =
              "1px solid rgba(54, 59, 77, 0.9)";
            refBorderRedPass.current.style.border =
              "1px solid rgba(54, 59, 77, 0.9)";
            setActiveSMSField(true);
            setActiveSMSFieldConfirmBtn(true);
            setActiveBtnDisactiveSMS(true);
            setDisactiveInputPhone(true);
            setAuthError("");
            setAuthPhoneCodeError("");
            setGetPhoneCode(true);
          }
        })
        .catch((err) => {
          throw new Error(err.message);
        });
    } else {
      setAuthError("Неверный формат номера");
      setAuthPhoneCodeError("");
      refBorderRedPhone.current.style.border = "1px solid red";
    }
  }

  function onDisactiveTimeOut() {
    if (disactiveInputPhone === true) {
      setDisactiveInputPhone(false);
      setActiveSMSFieldConfirmBtn(false);
      setActiveSMSField(false);
      setActiveBtnDisactiveSMS(false);
      setActiveSMSFieldMessage(false);
      setGetPhoneCode(false);
    }
  }

  function inputHandler() {
    if (changeAuthEmail === true && changeAuthPhoneEnterPass === true) {
      if (email.value === "" || password.value === "") {
        refBorderRedLogin.current.style.border = "1px solid red";
        refBorderRedPass.current.style.border = "1px solid red";
        setAuthError("Необходимо заполнить все поля");
      } else {
        refBorderRedLogin.current.style.border =
          "1px solid rgba(54, 59, 77, 0.9)";
        refBorderRedPass.current.style.border =
          "1px solid rgba(54, 59, 77, 0.9)";
        handleLogin(email.value, password.value);
        timeoutTimerSMSRef.current = null;
        intervalTimerSMSRef.current = null;
        setDisactiveInputPhone(false);
      }
    }
  }

  function inputHandlerPhone() {
    if (changeAuthPhone === true && changeAuthPhoneEnterPass === true) {
      if (email.value === "" || password.value === "") {
        refBorderRedPhone.current.style.border = "1px solid red";
        refBorderRedPass.current.style.border = "1px solid red";
        setAuthError("Необходимо заполнить все поля");
      } else {
        refBorderRedPhone.current.style.border =
          "1px solid rgba(54, 59, 77, 0.9)";
        refBorderRedPass.current.style.border =
          "1px solid rgba(54, 59, 77, 0.9)";
        handleLogin(email.value + "@sms.ru", password.value);
        timeoutTimerSMSRef.current = null;
        intervalTimerSMSRef.current = null;
        setDisactiveInputPhone(false);
      }
    }
  }

  function inputHandlerPhoneCode() {
    if (changeAuthPhone === true && getPhoneCode === true) {
      if (email.value === "" || refPhoneCode.current.value === "") {
        refPhoneCode.current.style.border = "1px solid red";
        setAuthError("Необходимо заполнить все поля");
      } else {
        handleLoginPhone(email.value, refPhoneCode.current.value);
        timeoutTimerSMSRef.current = null;
        intervalTimerSMSRef.current = null;
        setDisactiveInputPhone(false);
        refPhoneCode.current.style.border = "1px solid rgba(54, 59, 77, 0.9)";
      }
    }
  }

  function onEnterHandler() {
    if (changeAuthEmail === true) {
      inputHandler();
    } else if (changeAuthPhone === true && changeAuthPhoneEnterPass === true) {
      inputHandlerPhone();
    } else if (changeAuthPhone === true && getPhoneCode === true) {
      inputHandlerPhoneCode();
      refBorderRedPhone.current.style.border =
        "1px solid rgba(54, 59, 77, 0.9)";
      setAuthPhoneCodeError("");
    } else {
      setAuthPhoneCodeError("Необходимо ввести номер и получить код");
      setAuthError("");
    }
  }

  function messageRequareGetPhoneCode() {
    setAuthPhoneCodeError("Необходимо ввести номер и получить код");
    setAuthError("");
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // inputHandler();
      onEnterHandler();
    }
  };

  const handleKeyDownSMS = (e) => {
    if (e.key === "Enter") {
      messageRequareGetPhoneCode();
    }
  };

  useEffect(() => {
    if (changeAuthEmail === true && changeAuthPhoneEnterPass === true) {
      if (email.value !== "" && password.value !== "") {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      } else {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }
    } else if (changeAuthPhone === true && changeAuthPhoneEnterPass === true) {
      if (email.value !== "" && password.value !== "") {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      } else {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }
    } else if (changeAuthPhone === true && getPhoneCode === false) {
      if (email.value !== "") {
        document.addEventListener("keydown", handleKeyDownSMS);
        return () => {
          document.removeEventListener("keydown", handleKeyDownSMS);
        };
      } else {
        document.addEventListener("keydown", handleKeyDownSMS);
        return () => {
          document.removeEventListener("keydown", handleKeyDownSMS);
        };
      }
    } else if (changeAuthPhone === true && getPhoneCode === true) {
      if (email.value !== "" && refPhoneCode.current.value !== "") {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      } else {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }
    }
    // eslint-disable-next-line
  }, [
    email.value,
    password.value,
    changeAuthEmail,
    changeAuthPhone,
    changeAuthPhoneEnterPass,
    getPhoneCode,
  ]);

  const { isPhoneEnabled } = useAuthByPhone();

  return (
    <div className="wrapper-auth">
      <div className="container-auth">
        <div className="main-block">
          <div className="main-block__auth">
            <div className="auth__title">
              <h3>Авторизация</h3>
              {/*<div><span>РУС</span><span>ENG</span></div>*/}
            </div>
            <div className="auth__form">
              <div className="form__login">
                {isPhoneEnabled ? (
                  <div className="auth-form__select-email-phone-container">
                    Выполнить вход по:
                    <span
                      onClick={getChangeSelectEmail}
                      className={
                        changeAuthEmail
                          ? "auth-form__select-email active"
                          : "auth-form__select-email"
                      }
                    >
                      E-mail
                    </span>
                    /
                    <span
                      onClick={getChangeSelectPhone}
                      className={
                        changeAuthPhone
                          ? "auth-form__select-phone active"
                          : "auth-form__select-phone"
                      }
                    >
                      Телефону
                    </span>
                  </div>
                ) : null}

                {changeAuthEmail ? (
                  <input
                    type="email"
                    name="email"
                    placeholder={
                      activeTypeDisplay ? "user@mail.ru" : "stxxxxxx"
                    }
                    required
                    value={email.value}
                    onChange={email.onChange}
                    ref={refBorderRedLogin}
                  />
                ) : null}
                {changeAuthPhone ? (
                  <input
                    className="auth__form-phone-fields"
                    type="text"
                    name="phone"
                    required
                    placeholder={"7xxxxxxxxxx"}
                    minLength="11"
                    maxLength="11"
                    value={email.value}
                    onChange={email.onChange}
                    ref={refBorderRedPhone}
                    disabled={disactiveInputPhone}
                  />
                ) : null}
                {activeBtnDisactiveSMS ? (
                  <div
                    className="auth__form-disactive-sms-code"
                    onClick={onDisactiveTimeOut}
                  >
                    Повторно получить код
                  </div>
                ) : null}
              </div>
              <div className="form__pass">
                {changeAuthPhone ? (
                  <div className="auth-form__select-email-phone-container">
                    Выполнить вход по:
                    <p
                      onClick={getChangeSelectSMS}
                      className={
                        changeAuthPhoneEnterPass
                          ? "auth-form__select-email active"
                          : "auth-form__select-email"
                      }
                    >
                      Паролю
                    </p>
                    /
                    <p
                      onClick={getChangeSelectPhonePass}
                      className={
                        changeAuthPhoneEnterSMS
                          ? "auth-form__select-phone active"
                          : "auth-form__select-phone"
                      }
                    >
                      SMS-коду
                    </p>
                  </div>
                ) : null}
                {changeAuthEmail ? <span>Пароль</span> : null}
                {changeAuthPhoneEnterPass ? (
                  <img
                    alt="иконка"
                    className="form__pass-show-pass-icon"
                    src={
                      changeTypePass === "password"
                        ? show_pass_icon
                        : hidden_pass_icon
                    }
                    onClick={showHiddenPass}
                  />
                ) : null}
                {changeAuthPhoneEnterPass ? (
                  <input
                    type={changeTypePass}
                    name="password"
                    placeholder="Введите пароль"
                    required
                    value={password.value}
                    onChange={password.onChange}
                    ref={refBorderRedPass}
                  />
                ) : (
                  <input
                    className="form__pass-active-fields-phone"
                    type={changeTypePass}
                    name="password"
                    placeholder="Введите пароль"
                    required
                    value={password.value}
                    onChange={password.onChange}
                    ref={refBorderRedPass}
                  />
                )}
                {changeAuthPhoneEnterSMS ? (
                  <div className="auth__form-phone-sms-code-block">
                    <button
                      type={"button"}
                      onClick={(e) => onGetPhoneCode(e)}
                      className={
                        activeSMSFieldConfirmBtn
                          ? "auth__form-phone-sms-code-btn hidden"
                          : "auth__form-phone-sms-code-btn"
                      }
                    >
                      Получить код
                    </button>
                    <input
                      className={
                        activeSMSField
                          ? "auth__form-phone-sms-fields"
                          : "auth__form-phone-sms-fields hidden"
                      }
                      placeholder="Код из SMS"
                      type="text"
                      required
                      minLength="4"
                      maxLength="4"
                      value={phoneCode.value}
                      onChange={phoneCode.onChange}
                      ref={refPhoneCode}
                    />
                    <p
                      className={
                        activeSMSFieldMessage
                          ? "auth__form-phone-sms-message"
                          : "auth__form-phone-sms-message hidden"
                      }
                    >
                      Вы так и не получили код? Повторно код можно получить
                      через {second} сек
                    </p>
                  </div>
                ) : null}
                <div className="form__error">{authError}</div>
              </div>
              <div className="form__checkbox">
                <div className="checkbox__link-forget-pass-login-secretary">
                  {activeTypeDisplay ? (
                    <span
                      className="link-forget-pass"
                      onClick={() => linkButtonForgot("/forget")}
                    >
                      Забыли пароль?
                    </span>
                  ) : null}
                  <a
                    href={config.admin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Войти как секретарь
                  </a>
                </div>
                <div className="auth-form__checkbox-container">
                  <label className="checkbox_container">
                    <input
                      type="checkbox"
                      checked={isRememberMe}
                      onChange={handleRememberMe}
                    />
                    <span className="checkmark" />
                  </label>
                  <span className="auth__remember-me-checklabel">
                    Запомнить меня
                  </span>
                </div>
              </div>
              <div
                className={
                  activeTypeDisplay ? "form__button" : "form__button-spbu"
                }
              >
                <button
                  className={
                    preLoaderBtn
                      ? "form__button-input-btn active"
                      : "form__button-input-btn"
                  }
                  onClick={onEnterHandler}
                  type="button"
                >
                  {preLoaderBtn ? "Загрузка..." : "Войти"}
                </button>
                <div className="form__error-phone-code">
                  {authPhoneCodeError}
                </div>
                {config.enable_esia && (
                  <a
                    className="form__button__link-gosuslugi"
                    href="https://esia.gosuslugi.ru/login/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Войти через ЕСИА
                  </a>
                )}
              </div>

              {activeTypeDisplay ? (
                <div className="main-block__mobile-link-reg">
                  <span>Ещё нет аккаунта?</span>
                  <span onClick={() => linkButtonRegPage("/registration")}>
                    Зарегистрироваться
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="main-block__reg">
            <div className="reg__logo">
              <img src={rfbrLogo} alt="rfbr" />
              <img className="reg__logo-main" src={logo} alt="Логотип" />
            </div>
            <div className="reg__title">Система электронных голосований</div>
            {activeTypeDisplay ? (
              <div className="reg__button">
                <span>Еще нет аккаунта?</span>
                <button onClick={() => linkButtonRegPage("/registration")}>
                  Зарегистрироваться
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
