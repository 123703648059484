export const config = {
    java_api_url: "/api",
    admin_url: "https://admin.rfbr.cryptoveche.ru",
    ws_connect: "wss://rfbr.cryptoveche.ru/ws",
    ws_user: "client",
    ws_pass: "client",
    enable_esia: false,
    confidentiality: "https://docs.cryptoveche.ru/files/cryptoveche_pk2.pdf",
    type: 'main'
};
