import { config } from '../config';

const API_URL = config.java_api_url;

export const getNewTokens = async (refreshToken) => {
    return fetch(`${API_URL}/refresh_tokens`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`,
            
        },
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((data) => {
            localStorage.removeItem('jwt');
            return data;
        })
        .catch((err) => {
            throw new Error(err.message);
        });
}

export const authorize = async (email, password) => {
    return fetch(`${API_URL}/auth`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            email: email,
            password: password,
            authAs: "user"
        })
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((data) => {
            if (data.jwt) {
                localStorage.setItem('jwt', JSON.stringify(data.jwt));
            }
            return data;
        })
        .catch((err) => {
            if (err.status === 500) {
                throw new Error('Сервер временно недоступен');
            }
        });
};

export const authorizePhoneCode = async (phone, phoneCode) => {
    return fetch(`${API_URL}/auth`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            phone: phone,
            phoneCode: phoneCode,
            authAs: "user",
            authType: "phone"
        })
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((data) => {
            if (data.jwt) {
                localStorage.setItem('jwt', JSON.stringify(data.jwt));
            }
            return data;
        })
        .catch((err) => {
            if (err.status === 500) {
                throw new Error('Сервер временно недоступен');
            }
        });
}

export const registration = async (userData) => {
    return fetch(`${API_URL}/users/registration`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: userData.email,
            email: userData.email,
            password: userData.password,
            first_name: userData.first_name,
            second_name: userData.second_name,
            last_name: userData.last_name,
            utc_offset: userData.utc_offset,
            registration_type: userData.registration_type,
            phone: userData.phone,
            confirmation_code: userData.confirmation_code
        })
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            if (err.status === 500) {
                throw new Error('Сервер временно недоступен');
            }
            else if (err.status === 401) {
                throw new Error('Пользователь с таким email уже существует');
            }
        });
};

export const sendEmailForgetPassword = async (email) => {
    return fetch(`${API_URL}/users/reset/init/${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then(data => data)
        .catch((err) => {
            throw new Error(err.message);
        });
};

export const resetUserPassword = async (token, password) => {
    return fetch(`${API_URL}/users/password/${token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
                password: password
            }
        )
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            if (err.status === 500) {
                throw new Error('Сервер временно недоступен');
            } else {
                throw new Error(err.message);
            }
        });
};

export const getUserByToken = async (token) => {
    return fetch(`${API_URL}/users/reset/${token}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then(data => data)
        .catch((err) => {
            throw new Error(err.message);
        });
};

export const registrationUserByToken = async (userData) => {
    return fetch(`${API_URL}/users/registration/${userData.token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            password: userData.password,
            first_name: userData.first_name,
            second_name: userData.second_name,
            last_name: userData.last_name,
            utc_offset: userData.utc_offset,
            userFields: userData.userFields
        })
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            if (err.status === 500) {
                throw new Error('Сервер временно недоступен');
            }
        });
};

export const getPhoneCode = async (userData) => {
    return fetch(`${API_URL}/users/generate_phone_code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: userData.email,
            email: userData.email,
            password: userData.password,
            first_name: userData.first_name,
            second_name: userData.second_name,
            last_name: userData.last_name,
            utc_offset: userData.utc_offset,
            registration_type: userData.registration_type,
            phone: userData.phone
        })
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            if (err.status === 500) {
                throw new Error('Сервер временно недоступен');
            }
            else if (err.status === 401) {
                throw new Error('Пользователь с таким email уже существует');
            }
        });
};
export const getPhoneCodeAuth = async (phone) => {
    return fetch(`${API_URL}/auth/generate_phone_code/${phone}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then(data => data)
        .catch((err) => {
            throw new Error(err.message);
        });
};

export const getAuthByPhoneIsEnabled = async () => {
    return fetch(`${API_URL}/auth_by_phone_is_enabled`, {
        method: 'GET',

    })
        .then(res => res.ok ? res : Promise.reject(res))
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then(data => data)
        .catch((err) => {
            throw new Error(err.message);
        });
};